@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
html { scroll-behavior: smooth; }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Tùy chỉnh thanh cuộn cho trình duyệt Webkit (Chrome, Edge, Safari) */
body::-webkit-scrollbar {
  width: 15px; /* Độ rộng thanh cuộn dọc */
  height: 0px; /* Độ cao thanh cuộn ngang */
}

body::-webkit-scrollbar-track {
  background: linear-gradient(180deg, #38bdf8, #1e40af);

}

body::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, ghostwhite, ghostwhite); /* Gradient cho thanh cuộn */
  border-radius: 10px; /* Bo góc cho thanh cuộn */
  border: 2px solid #38bdf8; /* Tạo khoảng cách giữa thumb và track */
}

body::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(0deg, white, white); /* Gradient cho thanh cuộn */
}

/* Tùy chỉnh thanh cuộn cho các vùng cụ thể (nếu cần) */
.custom-scroll {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #38bdf8 #1e3a8a;
}

.custom-scroll::-webkit-scrollbar {
  width: 10px;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #1e3a8a;
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #38bdf8, #1e40af);
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #1e40af, #1e3a8a);
}
